<template>
    <footer class="home_footer_container align_center flex_justify_center">
        <div class="home_footer_wrap">
            <div class="dp_flex">
                <div class="footer_logo_wrap">
                    <img class="footer_logo" src="/media/img/logo_white.svg" alt="APLAYZ" />
                </div>
                <div class="download_wrap flex_column" v-if="!isen">
                    <span class="social_media_text download_txt">다운로드</span>
                    <button class="install_download_btn align_center" @click="onAddDownloadCount">
                        매장용 플레이어
                        <img class="install_download_img" src="/media/images/home/install_download.png" alt="install_download" />
                    </button>
                </div>
            </div>

            <ul class="dp_flex agree_information">
                <li class="agree_information_wrap flex_column">
                    <div class="agree_information_group" v-if="!isen">
                        <span class="agree_service cursor" @click="goHomeService">서비스 이용약관</span>
                        &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                        <span class="agree_service cursor" @click="goHomePrivacy">개인정보처리방침</span>
                    </div>
                    <div class="company_info_wrap pc">
                        <span class="info_item">
                            <slot name="infoitem1">
                                상호명 : (주)어플레이즈
                            </slot> </span
                            >&nbsp;
                        <span class="info_item">
                            <slot name="infoitem2">
                                대표 : 배정진
                            </slot> </span
                        >&nbsp;
                        <span class="info_item">
                            <slot name="infoitem3">
                                사업장 소재지 : 서울시 마포구 백범로31길 21 별관 301,302호
                            </slot> </span
                        >&nbsp; <span v-if="!isen" class="info_item">사업자 등록번호 : 756-86-02541</span>&nbsp;
                        <span v-if="!isen" class="info_item">통신판매업 신고번호 : 2022-서울마포-3718</span>&nbsp;
                        &nbsp;&nbsp;&nbsp;<span v-if="!isen" class="business_information" @click="checkBusinessInfo"
                        >사업자 정보확인</span
                        >
                    </div>
                    <div class="company_info_wrap tablet">
                        <span class="info_item">
                            <slot name="infoitem1">
                                상호명 : (주)어플레이즈
                            </slot>
              &nbsp;
                            <slot name="infoitem2">
                                대표 : 배정진
                            </slot>
                        </span>
                        <span class="info_item">
                            <slot name="infoitem3">
                                사업장 소재지 : 서울시 마포구 백범로31길 21 별관 301,302호
                            </slot>
                        </span>
                        <span class="info_item" v-if="!isen">사업자 등록번호 : 756-86-02541</span>
                        <p v-if="!isen">
                            <span class="info_item">
                                통신판매업 신고번호 : 2022-서울송파-2069
                            </span>
                            &nbsp;&nbsp;<span class="business_information" @click="checkBusinessInfo">사업자 정보확인</span>
                        </p>
                    </div>
                    <div class="aplayz_contact">
                        <span class="aplayz_contact_item">
                            <slot name="contactitem1">
                                문의 : contact@aplayz.co.kr
                            </slot>
                        </span>
            &nbsp;&nbsp;&nbsp;
                        <span class="aplayz_contact_item" v-if="!isen">고객센터 : 070-4060-8362</span>
                    </div>
                </li>
                <li class="social_media_group flex_column" :class="{ en: isen }">
                    <span class="social_media_text">
                        <slot name="socialmediatext1">
                            소셜미디어
                        </slot>
                    </span>
                    <ul class="social_img_group dp_flex">
                        <li class="sns_item">
                            <img class="sns_item_img" @click="openInstgram" src="/media/images/home/instgram.png" alt="인스타그램" />
                        </li>
                        <li class="sns_item middle">
                            <img class="sns_item_img youtube" @click="openYotube" src="/media/images/home/youtube.png" alt="유투브" />
                        </li>
                        <li class="sns_item" v-if="!isen">
                            <img
                                class="sns_item_img naver"
                                @click="openNaverBlog"
                                src="/media/images/home/naverblog.png"
                                alt="네이버블로그"
                            />
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </footer>
</template>
<script>
import { addDownloadCount } from '@/service/api/installprogramApi';
export default {
  name: 'HomeFooter',
  props: {
    isen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {};
  },

  mounted () {},

  methods: {
    checkBusinessInfo () {
      window.open('https://www.ftc.go.kr/bizCommPop.do?wrkr_no=7568602541', '_blank');
    },
    /**
     * @description 서비스이용약관
     * @author CHOI DAE GEON
     */
    goHomeService () {
      this.$router.push({ name: 'HomeService' }).catch(() => {});
    },
    /**
     * @description 개인정보처리방침
     * @author CHOI DAE GEON
     */
    goHomePrivacy () {
      this.$router.push({ name: 'HomePrivacy' }).catch(() => {});
    },

    /**
     * @description 인스타그램 오픈
     * @author CHOI DAE GEON
     */
    openInstgram () {
      window.open('https://www.instagram.com/aplayz_official/', '_blank');
    },
    /**
     * @description 유투브 오픈
     * @author CHOI DAE GEON
     */
    openYotube () {
      const isMobile = /Mobi/i.test(window.navigator.userAgent);
      if (isMobile) {
        window.open('https://m.youtube.com/@aplayz_official', '_blank');
      } else {
        window.open('https://www.youtube.com/@aplayz_official', '_blank');
      }
    },
    /**
     * @description 네이버블로그 오픈
     * @author CHOI DAE GEON
     */
    openNaverBlog () {
      window.open('https://blog.naver.com/aplayz_official', '_blank');
    },
    /**
     * @description 설치용 다운로드
     */
    onAddDownloadCount () {
      window.location.href = `https://www.aplayz.co.kr/media/download/setup_aplayz.zip?date=${new Date().getTime()}`;
      addDownloadCount();
    }
  }
};
</script>
<style scoped src="@/assets/css/home/homefooter.css"></style>
